/*jshint -W033 */
/*jshint esversion: 6 */
// constructor for object holding all cards to be held in DOM
/// <reference path="Jquery.d.ts"/>
// import "./jquery-import.js"
// import $ from "jquery";
// import * as $ from 'jquery'
// import JSConfetti from 'js-confetti'
// import pako from 'pako'
// import iziModal from 'iziModal'
// import "./jquery.drum.min.js"
// import Sortable from 'sortablejs/modular/sortable.core.esm.js';
declare function JSConfetti(): void
declare var Sortable
declare var pako
const axel_url = "https://axel.resumai.win"
class CardStack {
  stack: Array<Card>
  pos: number
  constructor () {
    this.stack = [] //holds card objects
    this.pos = 0 //current shuffle position
  }
  set addCard (card: Card) {
    this.stack.push(card) //add card to CardStack.stack
    this.pos = this.stack.length // set the current card as the top of the stack
  }
  get length () {
    return this.stack.length
  }
  get current () {
    return this.stack[this.pos]
  }
  get next () {
    if (this.pos < this.length - 1) {
      this.pos += 1
      return this.stack[this.pos]
    } else if (this.pos >= this.length - 1) {
      this.pos = 0
      return this.stack[this.pos]
    }
  }
}
// constuctor for individual card object
class Card {
  stack: CardStack
  val: string
  pos: number
  constructor (stack: CardStack, val: string) {
    this.stack = stack // a CardStack
    this.val = val // a value
    this.pos = stack.length //current position in the stack at time of entry
    stack.addCard = this // adds the card to the inserted CardStack.stack
  }
  get string () {
    return (this.pos + 1).toString() + ' of ' + this.stack.length //returns a string for use in the header of the card stack in DOM
  }
}
const card_stack = new CardStack() //creates card stack
// build a card on demand
async function buildCard (params) {
  // TODO: Validate all input params
  var requestBody = {}
  JSON.parse(localStorage.getItem('resume')!)
  localStorage.getItem('listing')
  if (
    localStorage.getItem('resume') == null ||
    localStorage.getItem('resume') == '<NONE>'
  ) {
    console.warn('Resume is blank')
    $('#modal_resume').iziModal('open')
    return 1
  } else if (
    localStorage.getItem('listing') == null ||
    localStorage.getItem('listing') == '<NONE>'
  ) {
    console.warn('Listing is blank')
    return 1
  } else if (
    localStorage.getItem('resumeMarkdown') == null ||
    localStorage.getItem('resumeMarkdown') == '<NONE>'
  ) {
    fetch(`${axel_url}/resumeformat`, {
      method: 'POST',
      body: localStorage.getItem('resume'),
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.text())
      .then(rawData => {
        localStorage.setItem('resumeMarkdown', rawData)
      })
      .catch(error => {
        console.error("Can't convert to markdown", error)
        return 1
      })
  }
  if (
    (localStorage.getItem('nuggetBody') == null ||
      localStorage.getItem('nuggetBody') == '<ZERO>') &&
    sessionStorage.getItem('jwtBody') != null
  ) {
    requestBody = {
      resume: localStorage.getItem('resumeMarkdown'),
      listing: localStorage.getItem('listing'),
      title: params.title,
      kind: params.type,
      org: params.org,
      token: sessionStorage.getItem('jwtBody')
    }
  } else if (localStorage.getItem('nuggetBody') != null) {
    requestBody = {
      resume: localStorage.getItem('resumeMarkdown'),
      listing: localStorage.getItem('listing'),
      title: params.title,
      kind: params.type,
      org: params.org,
      nugget: localStorage.getItem('nuggetBody')
    }
  } else {
    console.error('No nugget or jwt')
    return 1
  }
  var element = $('div.text_paper_content > p')[0]
  element.style.overflow = 'hidden'
  $('.text_paper_title > h3')[0].innerText = 'loading...'

  // Add the animation effect
  var style = document.createElement('style')
  style.type = 'text/css'
  style.innerHTML =
    '@keyframes wave { 0% {color: black;} 50% {color: #bfbfbf;} 100% {color: black;} }'
  document.getElementsByTagName('head')[0].appendChild(style)
  $('.text_paper_title > h3').css({
    'animation-name': 'wave',
    'animation-duration': '2s',
    'animation-iteration-count': 'infinite'
  })

  // Apply the scramble effect
  let intervalId = setInterval(() => {
    let scrambledText = ''
    for (let i = 0; i < element.innerText.length; i++) {
      const randomChar = Math.floor(Math.random() * 36)
      const charCode = randomChar < 10 ? randomChar + 48 : randomChar + 87
      scrambledText += String.fromCharCode(charCode)
    }
    element.innerText = scrambledText
  }, 50)

  // Make the POST request
  await fetch(`${axel_url}/paragraph2`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
    headers: { 'Content-Type': 'application/json' }
  })
    .then(response => response.text())
    .then(rawData => {
      const data = JSON.parse(rawData)
      if (data.nugget == '<ZERO>') {
        upgrade_prompt()
        console.error('Zero credits remain')
        return 1
      }
      localStorage.setItem('nuggetBody', data.nugget)
      const words = data.words
      clearInterval(intervalId) // Stop the scramble effect
      console.log(words)
      // Remove the animation effect
      $('.text_paper_title > h3').css({
        'animation-name': '',
        'animation-duration': '',
        'animation-iteration-count': ''
      })
      $('style').remove()

      // Create a helper function to update the text one character at a time
      const updateText = index => {
        if (index < words.length) {
          const nextChar = words.charAt(index)
          element.innerText =
            element.innerText.slice(0, index) +
            nextChar +
            element.innerText.slice(index + 1)
          setTimeout(() => updateText(index + 1), 0) // Continue the animation
        } else {
          element.innerText = words
          element.style.transition = 'all 1s ease-out' // Apply the transition effect
          $('.text_paper_title > h3')[0].innerText = new Card(
            card_stack,
            words
          ).string
          element.style.overflow = 'auto'
          return 0
        }
      }

      updateText(0) // Start the animation
    })
    .catch(error => {
      element.innerText = 'Timeout Error'
      element.style.transition = 'all 1s ease-out' // Apply the transition effect
      $('.text_paper_title > h3')[0].innerText = new Card(
        card_stack,
        'Timeout Error'
      ).string
      element.style.overflow = 'auto'
      console.error(error)
    })
    .finally(() => clearInterval(intervalId)) // Clear the interval once the response is received
}

function shakeDiv (div, placeString, words) {
  // Store the original text of the target child element
  const originalText = div.children[1].children[1].children[0].textContent
  div.children[1].children[1].children[0].style.overflow = 'hidden'
  // Apply the shake effect and scramble the text
  div.classList.add('shake')
  let scrambleInterval = setInterval(() => {
    let scrambledText = ''
    for (let i = 0; i < originalText.length; i++) {
      const randomChar = Math.floor(Math.random() * 36)
      const charCode = randomChar < 10 ? randomChar + 48 : randomChar + 87
      scrambledText += String.fromCharCode(charCode)
    }
    div.children[1].children[1].children[0].textContent = scrambledText
  }, 10)

  // Wait for the shake effect to complete and update the text
  setTimeout(() => {
    clearInterval(scrambleInterval) // Stop the text scramble effect
    $('.text_paper_title > h3')[0].innerText = placeString
    div.children[1].children[1].children[0].textContent = words // Change the text
    div.classList.remove('shake')
    div.children[1].children[1].children[0].style.overflow = 'auto' // Remove the shake effect
  }, 150) // 1 second duration of shaking effect
}

const shuffle_button = document.getElementById('shuffle_button')
if (shuffle_button != null) {
  shuffle_button.onclick = function () {
    if (card_stack.length > 0) {
      var nextCard = card_stack.next!
      shakeDiv(
        document.querySelector('div.text_paper'),
        nextCard.string,
        nextCard.val
      )
    } else {
      console.error('Empty card_stack')
    }
  }
} else {
  console.warn('shuffle_button not in DOM')
}

const build_button = document.getElementById('build_button')
if (build_button != null) {
  build_button.onclick = function () {
    buildCard(getParams())
    // updateElementWithPostResponse(document.querySelector("div.text_paper_content > p"))
  }
} else {
  console.warn('build_button not in DOM')
}

const add_button = document.getElementById('add_button')
if (
  add_button != null &&
  document.querySelector<HTMLElement>('div.text_paper_content > p') != null &&
  document.querySelector<HTMLElement>('#paper-holder') != null
) {
  add_button.onclick = function () {
    var new_element = document.createElement('p')
    // new_element.setAttribute("contenteditable","plaintext-only")
    new_element.innerText = document.querySelector<HTMLElement>(
      'div.text_paper_content > p'
    )!.innerText
    const paper_holder = document.getElementById('paper-holder')!
    paper_holder.appendChild(new_element)
    $('#paper-holder').scrollTop(paper_holder.scrollHeight)
    const jsConfetti = new JSConfetti()
    jsConfetti.addConfetti({
      emojis: ['🛠', '📈', '📑', '💷'],
      confettiNumber: 5
    })
  }
}

// resume = ''
// ;("I have a Bachelor's of Computer Science from American University. I worked as a QA Engineer for Acme.")
// listing = '<BLANK_LISTING>'

window.addEventListener(
  'message',
  event => {
    // We only accept messages from ourselves
    if (event.source !== window) {
      return
    }

    if (event.data.type && event.data.type === 'PASS_RESUME') {
      // console.log(event.data.text)
      localStorage.setItem('resume', event.data.text)
    } else if (event.data.type && event.data.type === 'PASS_LISTING') {
      // console.log(event.data.text)
      localStorage.setItem('listing', event.data.text)
    }
  },
  false
)

window.onload = function () {
  console.log('Repeat')
  if (localStorage.getItem('resume') != null){
    createOptions(JSON.parse(localStorage.getItem('resume')!))
    $('.drum').drum()
    window.addEventListener('resize', () => {
      // Get the current computed pixel width of the image
      const imgWidth = $('div.edit_resume > img')[0].getBoundingClientRect().width
  
      // Set the CSS attribute to the new computed pixel width of the image
      $('.drum-viewport')[0].style['-webkit-mask-size'] = `${imgWidth}px`
    })
    // $(".google_login_icon")[0].style["width"] = `${$(".facebook_login_icon")[0].style["width"]}px`;
    var chosen = $('.drum-item-current')[0].dataset.value
    if (typeof chosen!.split(' ') != 'undefined') {
      const chosenString = chosen!.split(' ')
      $('#resume_content')[0].innerHTML = formatNodeValues(
        JSON.parse(localStorage.getItem('resume')!)[chosenString[0]][parseInt(chosenString[1])]
      )
    } else {
      console.error('Job data is irregular')
    }
    observer.observe($('div.drum-viewport')[0], {
      attributes: true,
      childList: true,
      subtree: true
    })
  }
  else {
    console.log("Empty resume.. clearing..")
  }


  $('#modal_dl').iziModal()
  $('#modal_settings').iziModal({
    iframe: true,
    iframeURL: 'settings.html'
  })
  $('#modal_login').iziModal({
    closeOnEscape: false,
    closeButton: false,
    preventClose: true,
    openFullscreen: true,
    iframe: true,
    iframeURL: 'login.html'
  })
  $('#modal_upgrade').iziModal({
    closeOnEscape: false,
    closeButton: false,
    preventClose: true,
    openFullscreen: true,
    iframe: true,
    iframeURL: 'upgrade.html'
  })
  $('#modal_resume').iziModal({
    closeOnEscape: false,
    closeButton: false,
    preventClose: true,
    openFullscreen: false,
    iframe: true,
    iframeURL: 'resume.html'
  })
  $('#dl-ms-word')[0].children[0].addEventListener('click', function () {
    var link = document.createElement('a')
    document.body.appendChild(link)
    link.href = getDL()!
    link.click()
    link.remove()
  })
  $('.header_right_btn')[0].children[0].addEventListener('click', function () {
    $('#modal_dl').iziModal('open')
  })
  $('div.text_paper_items_icon > ul > li:nth-child(1) > a')[0].addEventListener(
    'click',
    function () {
      $('#modal_settings').iziModal('open')
    }
  )
  Sortable.create($('#paper-holder')[0])
  setTimeout(function () {
    if (sessionStorage.getItem('jwtBody') == null) {
      $('#modal_resume').iziModal('close')
      $('#modal_login').iziModal('open')
    }
  }, 1000)
  if (
    localStorage.getItem('resume') == null ||
    localStorage.getItem('resume') == '<NONE>'
  ) {
    console.warn('Resume is blank')
    $('#modal_resume').iziModal('open')
  }
}

function createOptions (json) {
  const select = document.getElementById('drum-selector')
  const keys = Object.keys(json)
  if (select != null && keys != null) {
    // First, process work experience
    if (keys.includes('workExperiences')) {
      const workExperience = json['workExperiences']
      workExperience.forEach((item, index) => {
        const text = item.title || item.name || item.text || item.degree
        const value = `workExperiences ${index}`
        const option = document.createElement('option')
        option.value = value
        option.textContent = text
        select.appendChild(option)
      })
    }

    // Next, process education
    if (keys.includes('educations')) {
      const education = json['educations']
      education.forEach((item, index) => {
        const text = item.title || item.name || item.text || item.degree
        const value = `educations ${index}`
        const option = document.createElement('option')
        option.value = value
        option.textContent = text
        select.appendChild(option)
      })
    }

    // Finally, process the rest of the keys in alphabetical order
    const remainingKeys = keys
      .filter(key => key !== 'workExperiences' && key !== 'educations')
      .sort()
    remainingKeys.forEach(key => {
      if (Array.isArray(json[key]) && json[key].length > 0) {
        json[key].forEach((item, index) => {
          const text = item.title || item.name || item.text || item.degree
          const value = `${key} ${index}`
          const option = document.createElement('option')
          option.value = value
          option.textContent = text
          select.appendChild(option)
        })
      }
    })
    var selectionElement = select.children[1] as HTMLOptionElement
    selectionElement.selected = true
  } else {
    console.warn('drum-selector or input JSON error')
  }
}

function getParams () {
  var chosen = $('div.drum-item.drum-item-current')[0]
    .getAttribute('data-value')!
    .split(' ')
  var job = JSON.parse(localStorage.getItem('resume')!)[chosen[0]][parseInt(chosen[1])]
  var params = {
    org: '',
    type: '',
    title: ''
  }
  switch (chosen[0]) {
    case 'workExperiences':
      params.org = job.company
      params.type = 'workExperiences'
      params.title = job.title
      break
    case 'educations':
      params.org = job.school
      params.type = 'educations'
      params.title = job.degree
      break
    case 'skillsList':
      params.org = ''
      params.type = 'skillsList'
      params.title = job.text
      break
    case 'certifications':
      params.org = ''
      params.type = 'certifications'
      params.title = job.title
      break
    case 'groups':
      params.org = ''
      params.type = 'groups'
      params.title = job.title
      break
    case 'assessments':
      params.org = ''
      params.type = 'assessments'
      params.title = job.name
      break
    case 'awards':
      params.org = ''
      params.type = 'awards'
      params.title = job.title
      break
    default:
      break
  }
  return params
}
async function advanceDrum () {
  const drumSelector = $('#drum-selector')[0] as HTMLSelectElement
  for (let index = 0; index < drumSelector.length - 1; index++) {
    await buildCard(getParams())
    const drumItem = $('div.drum-item.drum-item-current')
    await simulateDrum(drumItem)
  }
}
const observer = new MutationObserver((mutationsList, observer) => {
  for (const mutation of mutationsList) {
    if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
      if ($('.drum-item-current')[0].dataset.value == '\xa0') {
        simulateDrum($('div.drum-item.drum-item-current'))
      } else {
        var chosen = $('.drum-item-current')[0].dataset.value!.split(' ')
        $('#resume_content')[0].innerText = formatNodeValues(
          JSON.parse(localStorage.getItem('resume')!)[chosen[0]][parseInt(chosen[1])]
        )
        console.log('chosen')
      }
    }
  }
})

function formatNodeValues (node) {
  let values = ''
  for (const key in node) {
    if (
      node.hasOwnProperty(key) &&
      key !== 'degree' &&
      key !== 'text' &&
      key !== 'name' &&
      key !== 'title'
    ) {
      values += node[key] + '\n'
    }
  }
  return values
}

function gzipAndBase64Encode (str) {
  return encodeURIComponent(
    btoa(
      String.fromCharCode(
        ...new Uint8Array(pako.gzip(new TextEncoder().encode(str)))
      )
    )
  )
}
function getDL () {
  const paperHolder = document.querySelector<HTMLElement>('#paper-holder')
  if (paperHolder != null) {
    return (
      `${axel_url}/convert?d=` +
      gzipAndBase64Encode(paperHolder.innerText)
    )
  } else {
    console.error('Failed to get paper-holder')
  }
}
async function simulateDrum (drumItem) {
  await drumItem.trigger($.Event('keydown', { keyCode: 40 }))
  await drumItem.trigger($.Event('keyup', { keyCode: 40 }))
  await new Promise(resolve => setTimeout(resolve, 300))
}

function upgrade_prompt () {
  $('#modal_upgrade').iziModal('open')
}
